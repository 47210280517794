/** inquiry */
#inquiry{
    .info-title{
        padding:0 0 15px;
        @include font-size(21);
    }
    .inquiry-list{
        padding:0 2% 45px;
        .tb-wrap{
            table{@extend %table-style1;}
            th{
                padding:13px 0;
                background:$color1;
                &:first-child{width:100px;}
            }
            td{
                padding:0;
                border-bottom:1px solid #999;
                font-family:Arial,$font-ms;
                a{
                    transition: all .2s;
                    &:hover{color:$color1;}
                }
            }
            .figure{
                max-width:100px;
                line-height:0;
                img{
                    display:block;
                    margin: 0 auto;
                    //min-width:85px;
                }
            }
            .category{
                text-align:left;
                padding:0 2%;
                ul, li{margin: 0; padding: 0; list-style: none outside none;}
                li, a, li+li:before{
                    display:inline-block;
                    vertical-align:middle;
                }
                li{
                    color:#999;
                    &+li:before{
                        content:'';
                        width:5px; height:11px;
                        background:url(../images/icon-next.png) center no-repeat;
                        background-size:contain;
                        opacity:0.8;
                        margin:0 10px;
                    }
                    &:last-child{color:#ccc;}
                }
            }
            .del{
                min-width:75px;
                .del{
                    font-size: 0;
                    text-indent: -999px;
                    display: block;
                    width: 20px; height: 20px;
                    margin:0 auto;
                    background: url(../images/icon-del.png) center no-repeat;
                    background-size: contain !important;
                    overflow: hidden;
                    &:hover{background: url(../images/icon-del-hover.png) center no-repeat;}
                }
            }
        }
        .btn-wrap{
            margin:20px auto 0;
            .btn{
                width:100%;
                font-weight:bold;
                position:relative;
                &:before{
                    @extend %btn-icon;
                    width:8px; height:15px;
                    background:url(../images/icon-prev.png) center no-repeat;
                    margin:0 40px 2px 0;
                }
                &:hover{
                    &:before{@extend %move-back}
                }
            }
        }
    }
    .form-info{
        padding:0 4% 45px;
        @extend %fill-table;
    }
}
@media screen and (min-width: 480px) {
    #inquiry{
        .inquiry-list{
            .tb-wrap{
                table{
                    th:first-child{width:100px;}
                }
                .category{padding:0 4%;}
            }
        }
    }
}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    #inquiry{
        .inquiry-form{
            padding:0 0 65px;
            font-size:0;
        }
        .inquiry-list, .form-info{
            display:inline-block;
            vertical-align:top;
        }
        .info-title{
            @include font-size(27);
            padding:0 0 25px;
        }
        .inquiry-list{
            width:48%;
            padding:0 10px;
            .btn-wrap{
                .btn{
                    width:240px;
                    &:before{margin:0 20px 2px 0;}
                }
            }
        }
        .form-info{
            width:52%;
            padding:0 0 0 4%;
        }
    }
}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1280px) {
    #inquiry{
        .inquiry-list{width:31%;}
        .form-info{
            width:68%;
            padding:0 0 0 1%;
            .info-title{padding:0 0 25px 7%;}
        }
    }
}
@media screen and (min-width: 1440px) {
    #inquiry{
        .inquiry-list, .form-info{padding:0;}
        .inquiry-list{width:500px;}
        .form-info{width:900px;}
    }
}