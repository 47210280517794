/** 字型 */
@font-face {
    font-family: 'AvantGardeBkBTBook';
    src: url('font/AvantGardeBkBT/AvantGardeBkBTBook.eot');
    src: url('font/AvantGardeBkBT/AvantGardeBkBTBook.eot?#iefix') format('embedded-opentype'),
         url('font/AvantGardeBkBT/AvantGardeBkBTBook.woff2') format('woff2'),
         url('font/AvantGardeBkBT/AvantGardeBkBTBook.woff') format('woff'),
         url('font/AvantGardeBkBT/AvantGardeBkBTBook.ttf') format('truetype'),
         url('font/AvantGardeBkBT/AvantGardeBkBTBook.svg#AvantGardeBkBTBook') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AvantGardeBkBTDemi';
    src: url('font/AvantGardeBkBT/AvantGardeBkBTDemi.eot');
    src: url('font/AvantGardeBkBT/AvantGardeBkBTDemi.eot?#iefix') format('embedded-opentype'),
         url('font/AvantGardeBkBT/AvantGardeBkBTDemi.woff2') format('woff2'),
         url('font/AvantGardeBkBT/AvantGardeBkBTDemi.woff') format('woff'),
         url('font/AvantGardeBkBT/AvantGardeBkBTDemi.ttf') format('truetype'),
         url('font/AvantGardeBkBT/AvantGardeBkBTDemi.svg#AvantGardeBkBTDemi') format('svg');
    font-weight: normal;
    font-style: normal;
}