/** member */
#member{
    border-top:2px solid #eee;
    .intro-wrap{
        padding:0 5%;
        margin:40px auto 55px;
        @extend %fill-table;
        .info-title{padding:0 0 20px;}
        .fill-table{
            li{width:100%; padding:0;}
        }
    }
    .register-form, .signin-form, .forget-form{margin:0 auto;}
    .register-form{
        fieldset+fieldset{padding:25px 0 0;}
        .sub-title{padding:0 0 15px;}
    }
    .signin-form{
        .link-wrap{
            padding:20px 0;
            text-align:center;
            ul, li{margin: 0; padding: 0; list-style: none outside none;}
            li{
                display:inline-block;
                vertical-align:middle;
                &+li:before{
                    content:"/";
                    @include font-size(14);
                    color:#555;
                    padding:0 15px;
                }
            }
            a{
                @include font-size(13);
                font-weight:bold;
                color:#333;
                text-decoration:underline;
                &:hover{color:$color1;}
            }
        }
        .submit-wrap{
            text-align:center;
            .btn{width:100%;}
        }
    }
    .forget-form{
        .fill-table{
            .box{margin:0 auto;}
        }
    }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {
    #member{
        .intro-wrap{
            text-align:center;
            .submit-wrap{
                text-align:center;
                .btn{margin:0 auto;}
            }
        }
        .register-form{
            padding:0 10%;
            .sub-title{padding:0 0 25px;}
        }
        .signin-form{
            .submit-wrap{
                .btn{width:300px;}
            }
        }
    }
}
@media screen and (min-width: 768px) {
    #member{
        .register-form{padding:0 15%;}
        .signin-form{padding:0 7%;}
    }
}
@media screen and (min-width: 769px) {
    #member{
        .intro-wrap{
            margin:70px auto 90px;
            .info-title{padding:0 0 30px;}        
        }
        .register-form{
            max-width:580px;
            padding:0;
        }
        .signin-form{
            max-width:620px;
            padding:0;
            .fill-table{
                .box{max-width:360px;}
            }
            .link-wrap, .submit-wrap{
                display:inline-block;
                vertical-align:middle;
            }
            .link-wrap li{width:auto;}
            .submit-wrap{
                margin:0 0 0 22px;
                .btn{
                    width:100px;
                    margin:0 25px 0 0;
                }
            }
        }
    }
}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1280px) {}
@media screen and (min-width: 1440px) {
    #member{
        .intro-wrap{
            .fill-table{
                .box{width:360px;}
            }
        }
    }
}