#header {
	position: relative;
	margin:0 auto; padding: 0;
    background: #ffffff;;
    z-index: 999;
    .side-wrap {
        background:$color1;
        height:4px;
    }
    .account-wrap {
        max-width:1400px;
        margin:0 auto;
        text-align:center;
        border-top:1px solid #E3E3E3;
        padding:10px 2% 30px;
        overflow:hidden;
        a {
            display:block;
            padding:17px 0;
            font-family:Arial, $font-base;
            @include font-size(12);
            &:hover{text-decoration:underline;}
        }
        .skype-link{
            &:before{
                @extend %btn-icon;
                width:23px; height:23px;
                background:url(../images/icon-skype-g.png) center no-repeat;
                margin:0 7px 0 0;
            }
        }
    }
    .w-wrap{
        padding:14px 2%;
        line-height:1;
        overflow:visible;
    }
    .mobile-side {@extend %movableWrap;}
    .search-wrap {
        font-size:0;
        margin:0 auto;
        text-align:center;
        font-family:$font-ms;
        .form-wrap {
            width:90%;
            margin:0 auto;
            padding:18px 0;
        }
        .skin-wrap {
            position:relative;
            background:#FFF;
            border-radius:5px;
        }
        .keyword {
            width:100%;
            padding:8px 8px 8px 40px;
            border:0 none;
            background:none;
        }
        .btn-search {
            @include font-size(0);
            width:40px; height:100%;
            position:absolute;
            text-indent:-999px;
            left:10px; top:0;
            background:url(../images/icon-search.png) left center no-repeat;
            background-size:22px;
            background:url(../images/small/icon-search.png) left center no-repeat \9;
            border:0 none;
            overflow:hidden;
        }
        &.on { left:0; }
    }
}
#logo {
    position:relative;
    display:inline-block;
    vertical-align:middle;
    width:234px; height:35px;
    a, img{ display: block; }
}
.lang-wrap {
    color:#999;
    float:right;
    margin:0 15% 0 0;
    a { text-align: center;}
    .lang-btn {
        position:relative;
        border:0 none;
        display: block;
        padding:12px 10px;
        background:none;
        cursor:pointer;
        &:after { 
            content:''; display:block;
            position:absolute;
            right:0; top:50%;
            margin:-2px 0 0;
            width: 0; height: 0;
            border-style: solid;
            border-width: 4px 3px 0 3px;
            border-color: #333 transparent transparent transparent;
        }
    }
    .lang-nav {
        transition: all .3s;
        left:100%; top:100%;
        font-size:0;
        position:absolute;
        display:block; width:100%;
        background: #eee;
		overflow: hidden;
        a {
            display:block;
            padding:15px 0;
            @include font-size(14);
            border-top:1px solid #eee;
            transition: all .2s;
            &:hover, &.on {
                color:$color1;
                font-weight:900;
            }
        }
        ul, li{margin: 0; padding: 0; list-style: none outside none;}
        &.on { left:0; }
    }
}
#nav-btn {
    position:absolute;
    display:block; font-size:0;
    text-indent:-9999px;
    right:2%; top:21px;
    width:34px; height:34px;
    background:$color1;
    overflow:hidden;
    z-index:2;
    .thumb {
        width:18px; height:1px; background:#fff;
        display:block; position:absolute;
        left:50%; margin:0 0 0 -8px;
        transform-origin: center;
        &.thumb-1, &.thumb-2, &.thumb-3 {
        	transition: all .3s;
        }
        &.thumb-1 { top:11px; }
        &.thumb-2 { top:50%; margin-top:-1px; }
        &.thumb-3 { bottom:12px; }
    }
    &.on {
        .thumb { height:1px; }
        .thumb-1 {
            top:50%; margin-top:-2px;
            -moz-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
        .thumb-2 { @include opacity(0); }
        .thumb-3 {
            top:50%; margin-top:-2px;
            -moz-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
#main-nav {
    font-size: 0;
    margin:0; text-align:left;
    z-index:1;
    li:hover, li.on{
        >a{color:$color1;}
    }
    a {
        color:inherit;
        font-family:$font-bold;
        @include font-size(16);
        color:$color2;
    }
    >ul>li {
        >a {
            padding:18px 0;
            display: block;
            text-align:center;
            border-top:1px solid #E3E3E3;
        }
    }
    .sub-nav {
        display:none;
        position: absolute;
        padding: 0 10px;
        border: 1px solid #eee;
        background: #fff;
        li+li{border-top:1px solid #eee;}
        a{
            padding: 13px 20px;
            min-width: 200px;
            text-align: left;
        }
    }
    &.on {left:0;}
}
#search-btn {
    transition: all .2s;
    position:relative;
    display:none;
    width:22px; height:22px;
    text-indent:-999px;
    background:url(../images/icon-search.png) center no-repeat;
    background-size:contain;
    background:url(../images/small/icon-search.png) center no-repeat \9;
    overflow:hidden;
    font-size:0;
    border:0 none;
    outline:0 none;
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    #header {
        .side-wrap {height:0px;}
        .account-wrap {
            text-align:right;
            border:none;
            padding:0 4%;
            a {
                display:inline-block;
                vertical-align:middle;
                line-height:40px;
                padding:0;
                margin:0 0 0 30px;
                color:#fff;
            }
            .skype-link{
                &:before{
                    background:url(../images/icon-skype-w.png) center no-repeat;
                }
            }
        }
        >.w-wrap{padding:30px 2%;}
        .search-wrap {
            position:relative;
            z-index:1;
            float:right;
            width:40px; height:35px;
            text-align:right;
            margin:8px 0 0;
            overflow:hidden;
            transition: all .2s;
            .form-wrap {
                display:block;
                width:100%;
                position:absolute;
                top:0; right:-100%;
                padding:0;
                @include opacity(0);
            }
            .skin-wrap {
                background:#eee;
                overflow:hidden;
            }
            .keyword, .btn-search {
                float:right;
                color:#000;
            }
            .keyword{padding:8px 35px 8px 8px;}
            .btn-search{
                left:inherit; right:0;
                background:url(../images/icon-search.png) center no-repeat;
                background-size:22px;
                background:url(../images/small/icon-search.png) center no-repeat \9;
            }
            &.on {
                width:110px;
                #search-btn{
                    display:none;
                    @include opacity(0);
                }
                .form-wrap {
                    right:0;
                    @include opacity(1);
                }
            }
        }
        .lang-wrap {
            cursor:pointer;
            position:relative;
            margin:5px 0 0;
            z-index:2;
            li:first-child a{border:none;}
            a {
                padding:10px;
            }
            .lang-btn {
                right:0; top:0;
                position:relative;
            }
            .lang-nav {
                display:none;
                width:auto;
                top:auto; left:auto; right:0;
                min-width:140px;
                background:#fff;
                border:1px solid #eee;
                li {width:auto;}
            }
            &:hover {
                .lang-nav {
                    display:block;
                }
		    }
        }
    }
    #logo {
        float:left;
        width:352px; height:52px;
    }
    #nav-btn { display:none; }
    #main-nav {
        position:relative;
        z-index:0;
        text-align:right;
        > ul {
            > li {
                display:inline-block;
                vertical-align:middle;
                margin:0 5% 0 0;
                position:relative;
                > a {border:none;}
                &:hover {
                    .sub-nav {
                        display:block;
                    }
                }
            }
        }
    }
    #search-btn {
        display:inline-block;
        width:40px; height:35px;
        background-size:22px;
    }
}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1280px) {
    #header{
        .search-wrap.on{width:200px;}
    }
}
@media screen and (min-width: 1440px) {
    #header {
        .account-wrap {padding:0;}
        .w-wrap{padding:30px 0;}
    }
}