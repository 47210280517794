﻿//font-set
$font-comm:"AvantGardeBkBTBook",Arial, Helvetica, "Helvetica Neue", Tahoma, Verdana;
$font-base:$font-comm, "微軟正黑體", "Microsoft JhengHei", "MHei", "STHeitiTC-Light", sans-serif;
$font-ms:"微軟正黑體", "Microsoft JhengHei", $font-comm, sans-serif;
$font-bold:"AvantGardeBkBTDemi",$font-base;
$color1:#148DE7;
$color2:#0E63A0;
$input-color:#bbb;
//max-width
$full:1440px;

::-webkit-input-placeholder { /* WebKit browsers */
    @include font-size(12);
    color:$input-color;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    @include font-size(12);
   color:$input-color;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    @include font-size(12);
   color:$input-color;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    @include font-size(12);
   color:$input-color;
}
html {
	font-size:62.5%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
    height:100%;
}
body {
    background-color: #fff;
    @include font-size(15);
    position:relative;
    font-family: $font-base;
    color: #666;
    line-height:1.5;
    text-align:center;
    height:100%;
    &.lock {
        overflow:hidden;
        -webkit-backface-visibility: hidden;
    }
}
*, *:before, *:after {box-sizing: inherit;}
input,select,textarea{
    outline:medium;
    cursor:pointer;
    border:1px solid #aaa;
}
input{
    &[type="search"], &[type="text"], &[type="email"], &[type="tel"], &[type="url"], &[type="password"], &[type="reset"], &[type="submit"], &[type="button"] { @include font-size(16); padding:3px 5px; }
    &[type="checkbox"], &[type="radio"]{ vertical-align:middle; cursor:pointer; }
}
select {
    @include font-size(16); padding:6px;
     vertical-align:middle;
}
textarea {
	@include font-size(16); padding:3px 5px;
    height:100px;
    resize:none;
}
fieldset{margin:0; border:none;}
img{height: auto!important;}
h1, h2, h3, h4, h5, h6, strong, th {font-weight:bold; margin:0;}
p{margin:0;}
a, button {
    outline:0 none;
    text-decoration:none;
    color:inherit;
    outline:medium;
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
	input {
	     &[type="search"], &[type="text"], &[type="email"], &[type="tel"], &[type="url"], &[type="password"], &[type="reset"], &[type="submit"], &[type="button"]{ @include font-size(14); padding:5px; }
	}
	select, textarea {@include font-size(14);}
}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1280px) {}
@media screen and (min-width: 1500px) {}