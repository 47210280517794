﻿.web-figure { display:none; }
#container {
    min-width: 320px;
	margin:0 auto;
	position: relative;
	text-align: left;
    overflow:hidden;
}
#content {position:relative;}
.w-wrap  {
    max-width:1400px;
    margin:0 auto;
    overflow:hidden;
    @extend .clear;
}
.bg-video {
    position: relative;
    height: 0;
    padding: 0 0 56.25%;
    overflow: hidden;
    video, iframe, object {
        position: absolute;
        width: 100%; height: 100%;
        left: 0; top: 0;
        pointer-events: none;
    }
}
.intro-wrap{
    margin:25px auto 45px;
    overflow:hidden;
    @extend .clear;
}
.info-nav{
    padding:0 0 25px;
    font-size:0;
    text-align:center;
    ul, li {margin:0; padding:0; list-style:none outside none;}
    li {
        display:inline-block;
        vertical-align:top;
        margin:5px;
        &.on, &:hover{
            a{
                color:#fff;
                background:$color1;
                border:1px solid $color1;;
            }
        }
    }
    a {
        min-width:65px;
        padding:10px;
        @include font-size(14);
        font-family:$font-bold;
        color:#ccc;
        border:1px solid #CCC;
    }
}
.banner{
    font-size:0;
    margin:0 auto;
    overflow:hidden;
    a, img{display:block; margin: 0 auto;}
    .web { display:none; }
    .mobile img {
        max-width:none;
        width:100%;
    }
    .item{position:relative;}
    .bg {
        position:absolute;
        width:100%; height:100%;
        left:0; top:0;
        >img{visibility:hidden;}
    }
    .slick-prev, .slick-next {
		position: absolute;
		top:0;
        width:16px;
		height: 100%;
		border: 0 none;
		text-decoration: none;
		font-size: 0;
		text-indent: -999px;
		overflow: hidden;
        outline:0 none;
	}
	.slick-prev {
		left:25px;
		background: url(../images/arr1-prev.png) center no-repeat;
        background-size:contain;
        background: url(../images/small/arr1-prev.png) center no-repeat \9;
	}
	.slick-next {
		right:25px;
		background: url(../images/arr1-next.png) center no-repeat;
        background-size:contain;
        background: url(../images/small/arr1-next.png) center no-repeat \9;
	}
    .slick-dots {
        position: absolute;
        left: 0; bottom: 25px;
        width: 100%;
        text-align: center;
        margin: 0; padding: 0;
        font-size: 0;
        li {
            @include radius(50%);
            display: inline-block; vertical-align: middle;
            margin: 0 5px; padding: 0;
            list-style: none;
            border:2px solid #fff;
            width: 12px; height: 12px;
            overflow: hidden;
            &.slick-active { background: #fff; }
        }
        button {
            @include opacity(0);
        }
    }
    .w-wrap{
        position: relative;
        text-align: center;
        &:before{
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 0; height: 100%;
            min-height: 130px;
        }
    }
    .text{
        position:relative;
        display:inline-block;
        vertical-align:middle;
        width:99%;
        z-index:10;
    }
    .title{
        display:inline-block;
        max-width:65%;
        background:rgba(0,152,190,0.8);
        padding:12px 20px;
        font-family:$font-bold;
        font-weight:bold;
        @include font-size(33);
        color:#fff;
        line-height:1.2;
    }
}
nav, .path, .page-limit{
	ul, li {margin:0; padding: 0; list-style: none outside none;}
    a{
        display:block;
        transition:all .2s;
    }
}
.path {
        max-width:1400px;
        margin:0 auto;
        padding:10px 2%;
        li, a{
            display:inline-block;
            vertical-align:middle;
        }
    li {
        position:relative;
        @include font-size(12);
        font-family:"Verdana",$font-ms;
        &+li:before{
            content:'';
            display:inline-block;
            vertical-align:middle;
            width:7px; height:11px;
            background:url(../images/icon-next.png) center no-repeat;
            background-size:contain;
            opacity:0.5;
            margin:-2px 15px 0 15px;
        }
    }
    a {
        line-height:18px;
        color:#EEE;
        opacity:0.35;
        &:hover, &.on { opacity:1;}
    }
    .home{
        font-size:0;
        text-indent:-9999px;
        width:18px; height:15px;
        background:url(../images/icon-home.png) center no-repeat;
        margin:-2px 0 0;
    }
}
.page-limit {
    padding:0;
    text-align:center;
    ul {
        display:inline-block;
        font-size:0;
    }
    li {
        position:relative;
        display:inline-block;
        vertical-align:top;
        width:36px; height:36px;
        text-align:center;
        line-height:36px;
        background-size:auto 11px !important;
        &:hover, &.on {
            background-color:$color2;
            a {color:#fff;}
        }
    }
    a {
        position:relative;
        display:block;
        @include font-size(14);
        color:#555;
        z-index:1;
        @extend %fake-border;
        &:before{border-color:#e8e8e8;}
    }
    .first, .prev, .next, .last {
        font-size:0;
        text-indent:-999px;
        overflow:hidden;
        &:before { content:none; }
    }
    .first {background:url(../images/icon-first.png) center no-repeat;}
    .prev {background:url(../images/icon-prev.png) center no-repeat;}
    .next {background:url(../images/icon-next.png) center no-repeat;}
    .last {background:url(../images/icon-last.png) center no-repeat;}
}
.info-title, .comm-title {
    @include font-size(27);
    font-family:$font-bold;
    line-height:1.2;
    color:#333;
}
.sub-title {
    @include font-size(16);
    font-family:$font-bold;
    line-height:1.2;
    color:#004075;
}
.btn{
    border:none;
    display:inline-block;
    line-height:46px;
    text-align:center;
    padding:0 15px;
    font-family:$font-comm;
    @include font-size(14);
    letter-spacing:0.5px;
    transition:all .2s;
    &.oran{
        color:#fff;
        background:$color1;
        &:hover{background:$color2;}
    }
    &.blue{
        color:#fff;
        background:$color2;
        &:hover{background:$color1;}
    }
    &.gary{
        background:#eaeaea;
        border:1px solid #eaeaea;
        &:hover{
            background:#fff;
            border:1px solid $color1;
        }
    }
}
.info-head, .info-detail {
	max-width: 960px;
	margin:0 auto;
}
.slick-prev, .slick-next {
	outline: none;
    border:0 none;
}
.video-wrap{
    position: relative;
    height: 0;
    padding:56.25% 0 0;
    overflow: hidden;
    iframe{
        position: absolute;
        top: 0;  left: 0;
        width: 100%; height: 100%;
    }
}
#go-top{
    @include opacity(0);
    transition:opacity .5s;
    @include font-size(13);
    position: absolute;
    width: 0; height: 0;
    line-height:1;
    right: 15px;
    padding: 27px 0 0;
    color:#fff;
    font-family:Arial;
    font-weight:bold;
    text-align: center;
    background:$color1;
    overflow: hidden;
    z-index: 99;
    &:before {
        content:'';
        display:block;
        width:100%; height:10px;
        background:url(../images/icon-top.png) center no-repeat;
        position:absolute;
        top:10px;
    }
    &.block {
        @include opacity(1);
        position: fixed;
        bottom: 30px; right:7%;
        width: 50px; height:50px;
        border-radius:50%;
    }
}
@media screen and (min-width: 641px) {
    .banner{
        .web { display:block; }
        .mobile { display:none; }        
    }
}
@media screen and (min-width: 769px) {
    #container{ min-width:1024px; }
    .intro-wrap{margin:55px auto 55px;}
    .info-nav{
        padding:0 0 50px;
        li{margin:0 10px;}
        a{
            min-width:80px;
            padding:15px 20px;
        }
    }
    .banner{
        .w-wrap{
            &:before{min-height: 260px;}
        }
        .text{
            text-align:right;
            margin:0 0 0 1%;
        }
        .title{
            padding:30px;
            @include font-size(53);
        }
    }
    .path {padding:20px 10px;}
    .info-title, .comm-title {@include font-size(36);}
    .sub-title {
        @include font-size(18);
    }
    #go-top{
        &:hover{opacity:0.5;}
        &.block.end {
            position: absolute;
            bottom: auto;
        }
        &.end { margin: -25px 0 0; }
    }
}