/** search */
#search{
    header{
        padding:0 3% 35px;
        text-align:center;
    }
    .info-title{text-align:left;}
    .input-wrap{
        margin:30px auto 20px;
        width:100%;
        max-width:390px;
        border:1px solid #999;
        text-align:left;
        overflow:hidden;
        .keyword{
            display:inline-block;
            width: 71%;
            line-height: 32px;
            border:none;
        }
        .btn{
            width:85px;
            line-height:38px;
            float:right;
        }
    }
    .result-wrap{
        @include font-size(13);
        font-family:$font-ms;
        color:#000;
    }
    .item-group{@extend %pd-group;}
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {
    #search{
        .info-title{text-align:center;}
        .input-wrap{
            .keyword{width: 300px;}
        }
    }
}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    #search{
        header{}
        .input-wrap{
            .btn{line-height:42px;}
        }
        .item-group{
            .item{
                a{max-width:300px;}
            }
        }
    }
}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1280px) {}
@media screen and (min-width: 1400px) {}