/** home */
#home{
    .comm-title{@include font-size(21);}
    .comm-info{text-align:center;}
    .comm-products{
        .comm-title{padding:35px 0 20px;}
        .item-group{
            @extend %group-hover;
            max-width:1340px;
            margin:0 auto;
            padding:0 24px;
        }
        .item{
            display:inline-block;
            vertical-align:top;
            text-align:center;
            &:after{
                @extend %after-line;
                width:32%;
                background:#333;
                margin:15px auto;
            }
            a, .figure{margin: 0 auto;}
            a{
                padding:20px 20px 30px;
                max-width:310px;
                @extend %fake-border;
                &:hover{
                    &:before{border-color:#bbb;}
                    &:after{width:100%;}
                }
            }
            .figure{max-width:220px;}
            .text{
                transition: all .2s;
                color:#000;
                padding:10px 0 0;
                h3, span{@include font-size(13);}
                h3{
                    color:#000;
                    font-weight:normal;
                }
                span{color:$color1;}
            }
        }
        .slick-prev, .slick-next {
		    position: absolute;
		    top:0;
            width:18px; height: 100%;
		    text-decoration: none;
		    font-size: 0;
		    text-indent: -999px;
		    overflow: hidden;
            opacity:0.3;
            z-index:10;
            &:hover{opacity:1;}
	    }
	    .slick-prev {
		    left:10px;
		    background: url(../images/icon-news-prev.png) center no-repeat;
            background-size:contain;
	    }
	    .slick-next {
		    right:10px;
		    background: url(../images/icon-news-next.png) center no-repeat;
            background-size:contain;
	    }
    }
    .comm-main{
        a, img, h3,p , span{transition: all .2s;}
        .item-group{@extend %group-hover;}
        a:hover{
            .figure h3{bottom:20px;}
            p{color:#000;}
            .more{
                color:$color1;
                text-decoration:underline;
            }
        }
        .item{
            max-width:685px;
            margin:40px auto;
            overflow:hidden;
        }
        .figure{
            position:relative;
            line-height:1;
            overflow:hidden;
            h3{
                display:block;
                width:100%;
                text-align:center;
                @include font-size(29);
                font-family:$font-bold;
                color:#fff;
                line-height:1;
                position:absolute;
                bottom:-2px;
            }
        }
        .text{
            @include font-size(13);
            padding:15px 4% 0;
            max-width:580px;
            margin:0 auto;
            .more{
                display:inline-block;
                font-family:$font-bold;
                @include font-size(12);
                color:#148de7;
                padding:20px 0 0;
            }
        }
        .comm-about{
            a:hover{
                color:$color1;
                text-decoration:underline;
            }
        }
    }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {
    #home{
        .comm-main{
            .item{width:50%;}
            .figure{
                h3{
                    @include font-size(48);
                    bottom:-8px;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    #home{
        .comm-title{@include font-size(36);}
        .comm-products{
            .item{
                width:25%;
                a{padding:25px 30px 15px;}
            }
        }
        .comm-main{
            .item-group{
                max-width:1400px;
                margin:0 auto;
                font-size:0;
                overflow:hidden;
            }
            .item{
                width:50%;
                &.even{float:right;}
                &.odd{float:left;}
            }
            .figure{
                h3{
                    padding:0 30px;
                    text-align:left;
                }
            }
            .text{
                margin:0;
                @include font-size(15);
                text-align:left;
                padding:40px;
                .more{@include font-size(12);}
            }
            .comm-about{
                padding:70px 0 50px;
                .comm-title{
                    text-align:left;
                    padding:0 40px;
                }
                .text{max-width:100%;}
            }
        }
    }
}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1280px) {
    #home{
        .comm-products{
            .item-group{padding:0 50px;}
            .item a{padding:30px;}
            .slick-prev{left:0;}
            .slick-next{right:0;}
        }
    }
}
@media screen and (min-width: 1440px) {}