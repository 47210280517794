/** news */
#news{
    .news-cate{
        padding:0 2%;
        header{text-align:center;}
        .info-title{display:none;}
        .item-group{
            max-width:1240px;
            margin:0 auto;
            padding:0 0 25px;
            @extend %group-hover;
        }
        .item{
            display:inline-block;
            vertical-align:top;
            width:50%;
            max-width:310px;
            text-align:center;
            a{
                padding:20px 5px 10px;
                @extend %fake-border;
                &:hover{
                    color:#fff;
                    background:$color2;
                    .date:after{width:220px;}
                    .sub-title{color:#fff;}
                }
            }
            .figure{
                width:80%;
                max-width:220px;
                margin:0 auto;
            }
            .text{
                width:90%;
                margin:10px auto;
                @include font-size(12);
            }
            .date{
                &:after{
                    @extend %after-line;
                    width:32%;
                    margin:10px auto 0;
                    background:#999;
                }
            }
            .sub-title{
                font-family:$font-ms;
                padding:10px 0;
            }
        }
    }
    .news-detail{
        max-width:1000px;
        margin:0 auto;
        header{
            text-align:center;
            padding:0 2% 20px;
            .date{
                display:block;
                @include font-size(15);
                padding:15px 0 0;
            }
        }
        .text{
            padding:15px 4% 0;
            font-family:$font-ms;
        }
        .btn-wrap{
            font-size:0;
            padding:60px 2% 0;
            overflow:hidden;
            .btn{
                @include font-size(14);
                margin:0 0 10px;
                &:before, &:after{@extend %btn-icon;}
            }
            .page{overflow:hidden;}
            .prev, .next{
                display:inline-block;
                vertical-align:top;
                width:49%;
            }
            .prev{
                &:before{
                    width:8px; height:15px;
                    background:url(../images/icon-prev.png) center no-repeat;
                    margin:0 10px 2px 0;
                }
            }
            .next{
                &:after{
                    width:8px; height:15px;
                    background:url(../images/icon-next.png) center no-repeat;
                    margin:0 0 2px 10px;
                }
                &:hover:after{@extend %move-go;}
            }
            .back{
                width:100%;
                &:before{
                    width:18px; height:14px;
                    background:url(../images/icon-back.png) center no-repeat;
                    margin:0 10px 2px 0;
                }
            }
            .prev, .back{
                &:hover:before{@extend %move-back;}
            }
        }
        .slick-prev, .slick-next {
		    position: absolute;
		    top:0;
            width:18px;
		    height: 100%;
		    border: 0 none;
		    text-decoration: none;
		    font-size: 0;
		    text-indent: -999px;
		    overflow: hidden;
            outline:0 none;
            background-size:contain !important;
            transition: all .2s;
            &:hover{opacity:0.2;}
	    }
        .slick-prev{
            left:-10%;
            background:url(../images/icon-prev.png) center no-repeat;
        }
        .slick-next{
            right:-10%;
            background:url(../images/icon-next.png) center no-repeat;
        }
    }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {
    #news{
        .news-cate{
            .item{
                a{padding:30px 0;}
                .text{
                    margin:15px auto 0;
                    @include font-size(13);
                }
                .sub-title{padding:15px 0;}
            }
        }
    }
}
@media screen and (min-width: 768px) {
    #news{
        .news-cate{
            .item{
                width:33.33%;
                a{padding:20px 0;}
            }
        }
    }
}
@media screen and (min-width: 769px) {
    #news{
        .news-cate{
            .info-title{
                display:block;
                margin:0 0 30px;
            }
            .item-group{padding:0 0 40px;}
            .item{
                width:25%;
                .date{
                    &:after{width:100px;}
                }
            }
        }
        .news-detail{
            padding:0 0 40px;
            header{
                padding:0 0 40px;
                .date{@include font-size(18);}
            }
            .text{padding:40px 0;}
            .btn-wrap{
                padding:20px 0 0;
                text-align:right;
                .btn{margin:0;}
                .page{display:inline-block;}
                .prev, .next{
                    width:auto;
                    min-width:160px;
                    padding:0 30px;
                    margin:0 0 0 15px;
                }
                .next{
                    &:after{margin:0 0 2px 15px;}
                }
                .back{
                    float:left;
                    width:140px;
                }
                .prev, .back{
                    &:before{margin:0 15px 2px 0;}
                }
            }
        }
    }
}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1280px) {
    #news{
        .news-cate{
            .item{
                a{padding:30px 0 40px;}
                .text{width:85%;}
            }
        }
    }
}
@media screen and (min-width: 1440px) {
    #news{
        .news-cate{
            padding:0;
            .item{
                a{padding:40px 0;}
            }
        }
    }
}