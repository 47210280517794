/** contact */
#contact{
    .contact{padding:0 2%;}
    .info-title{@include font-size(21);}
    .company-info{
        background:#f7f7f7;
        .text{
            padding:20px 9% 40px;
            @include font-size(15);
            color:#444;
        }
        .info-title{padding:0 0 15px;}
        dl{
            &, dt, dd{margin: 0; list-style: none outside none;}
            padding:20px 0;
            dt,dd{padding:0 0 5px 0;}
            dt{
                min-width:65px;
                font-family:$font-bold;
                float:left;
                clear:left;
            }
        }
        .social-link{
            a{
                display:inline-block;
                vertical-align:middle;
                height:24px;
                font-size:0;
                text-indent:-9999px;
                background-size:contain !important;
                &+a{margin:0 0 0 25px;}
                &:hover{opacity:0.5;}
            }
            .icon-facebook{
                width:23px;
                background:url(../images/icon-fb-b.png) center no-repeat;
            }
            .icon-instagram{
                width:25px;
                background:url(../images/icon-ig-b.png) center no-repeat;
            }
            .icon-mail{
                width:25px;
                background:url(../images/icon-mail-b.png) center no-repeat;
            }
            .icon-twitter{
                width:24px;
                background:url(../images/icon-twitter-b.png) center no-repeat;
            }
            .icon-youtube{
                width:53px;
                background:url(../images/icon-youtube-b.png) center no-repeat;
            }
            .icon-skype{
                width:23px;
                background:url(../images/icon-skype-b.png) center no-repeat;
            }
        }
        .figure{text-align:center;}
        .map-wrap{
            width:100%; min-height:220px;
            position:relative;
            iframe{
                position:absolute;
                top:0; left:0;
                width:100%; height:100%;
            }
        }
    }
    .contact-form{
        padding:30px 4% 20px;
        .info-title{padding:0 0 20px;}
        @extend %fill-table;
    }
    .contact-done{
        text-align:center;
        padding:0 5% 50px;
        .info-title{@include font-size(27);}
        .prompt{
            padding:50px 0;
            @include font-size(15);
            color:#333;
        }
        .btn{min-width:180px;}
    }
}
@media screen and (min-width: 769px) {
    #contact{
        .company-info{
            float: left;
            width:380px;
            .text{padding:35px 9%;}
        }
        .contact-form{
            header{padding:0 0 10px 2%;}
        }
        .contact-done{
            .info-title{@include font-size(36);}
            .prompt{
                padding:70px 0;
                @include font-size(17);
            }
        }
    }
}
@media screen and (min-width: 1280px) {
    #contact{
        .company-info{width: 35%;}
        .contact-form{
            max-width: 700px;
            margin: 0 auto;
            padding: 30px 0 0;
        }
    }
}
@media screen and (min-width: 1440px) {
    #contact{
        .intro-wrap{margin:40px auto 80px;}
        .contact{padding:0;}
    }
}