%movableWrap {
    transition: all .3s;
    position:absolute;
    top:100%;
    width:100%; left:-100%;
    background:#eee;
    overflow-y:auto;
    &.on { left:0; }
}
%hover-black{
    background:#333;
    color:#fff;
    transition: all .2s;
}
%hover-pic{
    img { transform: scale(1.1); }
}
%list-style1 {
    margin:0; padding:0; list-style:none;
    li {
        position:relative;
        padding:0 0 0 30px;
        margin:5px auto;
        &:before {
            content:''; display:block;
            width:20px; height:20px;
            background:url(../images/icon-chk1.png) center no-repeat;
            position:absolute;
            left:0; top:2px;
        }
    }
}
%fill-table {
    font-size:0;
    overflow:hidden;
    .sub-title{@include font-size(21);}
    i {
        color:#E50012; margin:0 5px;
        font-family:Arial,$font-ms;
        font-style:normal;
        font-weight:900;
    }
    .prompt {
        @include font-size(15);
        color:#444;
        margin:0 auto 20px;
    }
    ul, li {margin:0; padding:0; list-style:none outside none;}
    li {
        position:relative;
        margin:0 auto 15px;
        overflow:hidden;
    }
    .label, .box {@include font-size(15);}
    .label {
        font-weight:normal;
        color:#666;
        margin:0 0 5px;
    }
    .box {
        width:100%;
        overflow:hidden;
        input, select, textarea{
            width:100%;
            &:focus{
                border-bottom:2px solid $color1;
            }
        }
    }
    .w01, .w02 {
        &textarea{
            height:150px;
        }
    }
    .code-wrap {
        padding:0 0 7px;
        .code, .reload{
            display:inline-block;
            vertical-align:middle;
        }
        .code{width:60%; max-width:185px;}
        .reload{
            width:37%;
            margin:30px 0 0 1%;
            @include font-size(12);
            text-decoration:underline;
            white-space:nowrap;
        }
    }
    .submit-wrap{
        @extend .clear;
        clear:both;
        padding:15px 0 0;
        overflow:hidden;
        .btn{width:180px;}
    }
}
%news-list {
    ul, li {
        margin:0; padding:0; list-style:none outside none;
    }
    li {
        padding:20px;
        border-bottom:1px solid #eee;
        color:#333;
        &:first-child {
            background:#eee;
            padding:30px 20px;
            h3 {
                @include font-size(16);
                line-height:1.2;
            }
            .time {
                color:#333;
            }
            .text {
                margin:0 auto;
            }
        }
    }
    a {
        display:block;
        text-decoration:none;
    }
    h3 {
        @include font-size(15);
    }
    p {
        @include font-size(13);
        color:#999;
    }
    .more {
        @include radius(4px);
        display:inline-block;
        background:#E5001E;
        color:#fff;
        text-align:center;
        margin:20px 0 0;
        padding:5px 20px;
    }
    .time {
        @include font-size(13);
        display:block;
        color:#555;
    }
    .figure {
        display:inline-block;
        vertical-align:top;
        border:1px solid #ddd;
    }
    .text {
        @include font-size(13);
        margin:10px auto 0;
    }
}
%news-detail {
    @include font-size(13);
    padding:0 20px;
    color:#666;
    .time { margin:20px 0; }
    .editor {
        p { margin:0 auto 20px;}
    }
    .btn-back {
        text-decoration:none;
        display:inline-block;
        padding:10px 20px 10px 15px;
        background: #E5001E;
        color:#fff;
        font-weight:bold;
        &:before {
            content:''; display:inline-block;
            vertical-align:middle;
            width:24px; height:17px;
            margin:0 10px 0 0;
            background:url(../images/icon-back.png) center no-repeat;
            background-size:24px 17px;
            background:url(../images/small/icon-back.png) center no-repeat \9;
        }
    }
}
%table-style1 {
    border-collapse:collapse;
    width:100%;
    @include font-size(13);
    th, td {
        text-align:center;
        vertical-align:middle;
    }
    th {color:#fff;}
}
%group-hover {
    font-size:0;
    overflow:hidden;
    .item{position:relative;}
    a{
        display:block;
        overflow:hidden;
        transition: all .2s;
        .figure{overflow:hidden;}
        &:hover{@extend %hover-pic;}
    }
    img {
        display:block;
        margin:0 auto;
        transition: all .2s;
    }
}
%pd-group{
    @extend %group-hover;
    padding:0 1% 25px;
    .item{
        display:inline-block;
        vertical-align:top;
        width:50%;
        margin:0 auto 25px;
        a{
            position:relative;
            max-width:230px;
            padding:0 2%;
            margin:0 auto;
            overflow:hidden;
        }
        .text{
            text-align:center;
            padding:10px 0 0;
            font-family:$font-bold;
            color:$color2;
            z-index:2;
            .title{@include font-size(16);}
            p{display:none;}
        }
    }
    .tag a:before{
        content:'';
        display:block;
        width:70px; height:70px;
        background:url(../images/icon-new.png) center no-repeat;
        position:absolute;
        top:0; right:0;
        z-index:1;
    }
}
%fake-border{
    position:relative;
    &:before{
        content:'';
        display:block;
        width:100%; height:100%;
        border:1px solid #EEE;
        position:absolute;
        top:0; left:0;
    }
}
%after-line{
    content:'';
    display:block;
    height:1px;
    transition: all .5s;
}
%btn-icon{
    content:'';
    display:inline-block;
    vertical-align:middle;
    background-size:contain !important;
    transition: all .3s;
}
%move-back{
    -webkit-transform:translateX(-10px);
    -o-transform:translateX(-10px);
    -moz-transform:translateX(-10px);
    transform:translateX(-10px);
}
%move-go{
    -webkit-transform:translateX(10px);
    -o-transform:translateX(10px);
    -moz-transform:translateX(10px);
    transform:translateX(10px);
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {
    %pd-group{
        padding:0 7% 25px;
        .item{
            a{padding:0;}
        }
    }
    %fill-table {
        .label {
            width:145px;
            float:left;
            padding:4px 15px 0 0;
            text-align:right;
        }
        .box {
            width: auto;
            margin:0 0 0 145px;
        }
        .submit-wrap{
            text-align:left;
            .btn{margin:0 0 0 140px;}
        }
    }
}
@media screen and (min-width: 768px) {
    %pd-group{
        padding:0 0 25px;
        .item{width:33.33%;}
    }
}
@media screen and (min-width: 769px) {
    %movableWrap {
        position:static;
        left:0; top:0;
        margin:0 8% 0 0;
        width:auto;
        background:none;
        overflow-y:visible;
        border-top:0 none;
        z-index:1;
        &.on { left:0; }
        &:after {
            content:''; clear:both;
        }
    }
    %fill-table {
        .prompt{margin:0 auto 40px;}
        .code-wrap {
            .code{width:60%;}
            .reload{width:37%;}
        }
        .submit-wrap{padding:0;}
    }
    %pd-group{
        .item{
            width:33.33%;
            a{
                padding:0;
                &:hover{
                    .text{
                        top:0;
                        .title:after{width:50px;}
                    }
                }
            }
            .text{
                transition: all .3s;
                padding:0 5%;
                position:absolute;
                top:-100%; left:0;
                width:100%; height:100%;
                color:#fff;
                background:rgba(0,35,140,0.7);
                .title{
                    width:100%;
                    text-align:center;
                    @include font-size(20);
                    position:absolute;
                    top:50%;
                    margin:-15px 0 0;
                }
            }
        }
    }
    %news-list {
        li {
            margin:0 auto 30px;
            padding:30px 40px;
            overflow:hidden;
            &:first-child {
                h3 {
                    @include font-size(20);
                }
                .time {
                    position:static;
                    left:auto;
                    top:auto;
                    margin:0 auto 5px;
                }
                .text {
                    margin:0;
                }
            }
        }
        h3 {
            @include font-size(18);
        }
        .figure { float:left; }
        .time {
            position:absolute;
            right:0; top:0;
        }
        .text {
            position:relative;
            margin:0 0 0 130px;
            padding:0 80px 0 0;
        }
    }
    %news-detail {
        @include font-size(14);
        overflow:hidden;
        .editor {
            float:left;
            width:70%;
        }
        .btn-wrap {
            float:right;
            width:30%;
            text-align:right;
            .btn-back {
                padding:15px 20px;
            }
        }
    }
    %slogan {
        @include font-size(26);
        padding:45px 20px;
    }
}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1280px) {
    %movableWrap{margin:0 6% 0 0;}
    %fill-table {
        li{margin:0 auto 20px;}
    }
    %pd-group{
        .item{
            width:25%;
            padding:0 0.5%;
        }
    }
}
@media screen and (min-width: 1440px) {
    %pd-group{
        .item{padding:0;}
    }

}