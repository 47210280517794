@mixin font-size($num: 12) {
    font-size: $num + px;
    font-size: ($num / 10) + rem;
}
@mixin radius($radius:5px){
    border-radius:$radius;
}
@mixin forged-border($border:1px solid #ccc) {
    position:absolute; display:block;
    width:100%; height:100%;
    left:0; top:0;
    z-index:1;
    border:$border;
}
@mixin opacity($opacity: 0.5) {
    filter: alpha(opacity=$opacity*100);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=#{$opacity*100});
    opacity: $opacity;
}
%tinyscroll {
    position:relative;
    .viewport {
        height: 570px;
        overflow: hidden;
        position: relative;
    }
    .overview {
        position: relative;
        left: 0;
        top: 0;
    }
    .scrollbar {
        position: absolute;
        right:-20px;
        /*margin:0 0 5px;
        clear:both;
        height:15px;
        background:transparent url(../images/bg-scrollbar-track-x.png) no-repeat 0 0;*/
    }
    .track {
        position: relative;
        width: 5px;
        z-index: 9;
        @include radius(5px);
        background:#ccc;
        /*background:transparent url(../images/bg-scrollbar-trackend-x.png) no-repeat 100% 0;
        height:15px;*/
        .thumb {
            @include radius(5px);
            //background: $blue;
            cursor: pointer;
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 0;
            .end {
                //background: $blue;
                overflow: hidden; width: 5px;
            }
        }
    }
    .disable { display: none; }
}
.noSelect {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}