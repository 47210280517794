/** about */
#about{
    .about{
        max-width:1250px;
        margin:0 auto;
        padding:0 7%;
        text-align:center;
    }
    .info-title{
        padding:15px 0 10px;
        @include font-size(21);
    }
    .part{
        font-size:0;
        overflow:hidden;
        .text{
            @include font-size(15);
            padding:0 0 40px;
        }
    }
    .about-link{
        header{padding:55px 0 20px;}
        .item-group{@extend %group-hover;}
        .item{
            max-width:313px;
            margin:0 auto;
        }
        .text{
            position:absolute;
            bottom:0;
            width:100%;
            padding:10px;
            background:rgba(0,0,0,0.5);
            text-align:center;
            @include font-size(14);
            color:#fff;
        }
        .title{
            font-weight:normal;
            text-align:center;
        }
    }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {
    #about{
         .about-link{
             max-width:625px;
             margin:0 auto;
             overflow:hidden;
             .item{
                 max-width:none;
                 display:inline-block;
                 vertical-align:top;
             }
             .four{width:50%;}
        }    
    }
}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    #about{
        .about{padding:0 10px;}
        .info-title{@include font-size(36);}
        .part{
            margin:0 0 40px;
            .figure, .text{
                display:table-cell;
                vertical-align:middle;
                width:50%;
            }
            .text{
                text-align:left;
                padding:0 3.5%;
            }
        }
        .about-link{
            max-width:none;
            header{
                padding:15px 0;
                text-align:left;
            }
            .half{width:50%;}
            .four{width:25%;}
            .item:hover{
                .text{top:0;}
            }
            .text{
                transition: all .3s;
                position:absolute;
                top:-100%; left:0;
                width:100%; height:100%;
                padding:0;
                .title{
                    width:100%;
                    margin:0 auto;
                    padding:0 5%;
                    @include font-size(25);
                    position:absolute;
                    top:39%; left:0;
                }
            }  
        }
    }
}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1280px) {
    #about{
        .part{
            .figure{width:44%;}
            .text{
                width:56%;
                &.padd-left{padding:0 0 0 75px;}
                &.padd-right{padding:0 75px 0 0;}
            }
        }
        .about-link{
            .text{
                .title{padding:140px 0 0;}
            }
        }
    }
}
@media screen and (min-width: 1440px) {
    #about{
        .about{padding:0;}
        .about-link{
            .text{
                .title{padding:150px 0 0;}
            }
        }
    }
}