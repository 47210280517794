/** products */
#products{
    .side-nav{
        >ul>li{
            >a{
                padding:10px 7%;
                border-bottom:1px solid #ddd;
                font-family:$font-bold;
                color:#999;
                position:relative;
                &:after{
                    content:'';
                    display:block;
                    width:12px; height:12px;
                    background:url(../images/icon-plus.png) center no-repeat;
                    background-size:contain !important;
                    position:absolute;
                    top:14px; right:24px;
                }
                &:hover{background:#efefef;}
            }
            &.on{
                .sub-nav{display:block;}
                >a{
                    color:#fff;
                    background:$color2;
                    &:after{background:url(../images/icon-plus-on.png) center no-repeat;}
                }
            }
        }
        .sub-nav{
            display:none;
            padding:10px 25px 15px;
            font-family:$font-ms;
            @include font-size(13);
            border-bottom:1px solid #ddd;
            a{padding:5px 10px;}
            .on, a:hover{
                font-weight:bold;
                color:$color1;
            }
        }
    }
    .products-cate{
        max-width:1400px;
        margin:0 auto;
        text-align:center;
        header{display:none;}
        .item-group{
            @extend %group-hover;
             h3, p, span{transition: all .2s;}
            .item{
                margin:0 auto 40px;
                overflow:hidden;
            }
            a{
                display:block;
                max-width:440px;
                margin:0 auto;
                &:hover{
                    .figure h3{bottom:20px;}
                    p{color:#000;}
                    .more{
                        color:$color1;
                        text-decoration:underline;
                    }
                }
            }
            .figure{
                position:relative;
                line-height:1;
                overflow:hidden;
                h3{
                    display:block;
                    width:100%;
                    text-align:center;
                    @include font-size(29);
                    font-family:$font-bold;
                    color:#FFF;
                    line-height:1;
                    position:absolute;
                    bottom:-2px;
                    z-index:1;
                }
            }
            .text{
                @include font-size(14);
                padding:15px 4% 0;
                margin:0 auto;
                .more{
                    display:inline-block;
                    font-family:$font-bold;
                    @include font-size(12);
                    color:#CCC;
                    padding:20px 0 0;
                }
            }
        }
    }
    .products-list,.products-detail{
        .item-group{@extend %pd-group;}
    }
    .products-list{
        padding:0 0 50px;
        header{padding:0 3.5% 30px;}
    }
    .products-detail{
        padding:0 2%;
        header{
            padding:0 2% 20px;
            span{
                font-family:$font-bold;
                @include font-size(16);
                color:#666;
            }
        }
        .figure-wrap{
            max-width:460px;
            margin:0 auto;
            .max-figure{
                max-width:450px;
                margin:0 auto;
            }
            .min-figure{
                @extend %group-hover;
                .item{
                    padding:0 5px;
                    opacity:0.7;
                    &:hover,&.on{opacity:1;}
                }
            }
            .slick-prev, .slick-next {
		        position: absolute;
		        top:0;
                width:8px; height: 100%;
		        border: 0 none;
		        font-size: 0;
		        text-indent: -999px;
		        overflow: hidden;
                outline:0 none;
                opacity:0.3;
                background-size:contain !important;
                z-index:1;
	        }
	        .slick-prev {
		        left:-5px;
		        background: url(../images/icon-prev.png) center no-repeat;
	        }
	        .slick-next {
		        right:-5px;
		        background: url(../images/icon-next.png) center no-repeat;
	        }
        }
        .features-wrap, .detail{
            padding:0 2%;
            font-family:$font-ms;
        }
        .sec-part{margin:30px 0;}
        .sub-title{
            color:$color1;
            padding:0 0 8px;
        }
        .list-style{
            &, li{margin: 0; padding: 0; list-style: none outside none;}
            li{
                padding:0 0 5px 20px;
                position:relative;
                &:before{
                    content:'';
                    display:block;
                    width:5px; height:5px;
                    background:$color1;
                    border-radius:50%;
                    position:absolute;
                    top:8px; left:5px;
                }
            }
        }
        .btn-wrap{
            margin:0 0 50px;
            .btn{
                width:180px;
                &:before{
                    content:'';
                    display:inline-block;
                    vertical-align:middle;
                    width:20px; height:23px;
                    background:url(../images/icon-addto.png) center no-repeat;
                    background-size:contain;
                    margin:0 10px 3px 0;
                }
            }
        }
        .desc-wrap{
            table{border-collapse:collapse; width:100%;}
            td{
                display:block;
                vertical-align:middle;
            }
            .figure{
                margin:15px auto 0;
                text-align:center;
            }
        }
        .table-editor, .tb-wrap{
            overflow-x:auto;
            table{
                @extend %table-style1;
                tr:nth-child(odd) {background:#F4F4F9;}
                th,td{padding:10px 5px;}
                td{ color: #666;}
                th{background:$color2;}
            }
        }
        .table-editor, .free-editor{
            img{height:auto !important;}
        }
        .rela-pd{
            padding:30px 0 50px;
            .sub-title{padding:0 0 15px;}
            .item-group{padding:0;}
        }
    }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {
    #products{
        .products-cate{
            .item-group{
                .figure{
                    h3{
                        @include font-size(37);
                        bottom:0px;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px) {
    #products{
        .products-list,.products-detail{
            padding:0 2% 25px;

        }
    }
}
@media screen and (min-width: 769px) {
    #products{
        .intro-wrap{padding:0 10px;}
        .side-nav{
            float:left;
            width:25%;
            border:1px solid #ddd;
            border-bottom:none;
        }
        .products-cate{
            text-align:left;
            header{
                display:block;
                text-align:center;
                padding:0 0 40px;
            }
            .item-group{
                .item{
                    display:inline-block;
                    vertical-align:top;
                    width:50%;
                }
                .figure{
                    h3{
                        padding:0 4%;
                        text-align:left;
                    }
                }
            }
        }
        .products-list,.products-detail{
            display:inline-block;
            width:75%;
        }
        .products-list{
            padding:0 0 0 10px;
            header{padding:0 0 30px 2%;}
        }
        .products-detail{
            .sub-title{padding:0 0 12px;}
            header{padding:0 0 20px;}
            .features-wrap, .detail{padding:0;}
            .detail{
                .desc-wrap{
                    .figure{
                        white-space:nowrap;
                        img{
                            display:inline-block;
                            width:49%;
                            min-width:235px;
                            max-width:300px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1280px) {
    #products{
        .products-cate{
            .item-group{
                .item{
                    width:32%;
                    &:nth-child(3n+2){margin:0 2%;}
                }
            }
        }
        .products-list,.products-detail{padding:0 0 0 2%;}
        .products-detail{
            header{
                float:right;
                width:50%;
                padding:0 0 10px 0;
            }
            .sample-wrap{
                margin:0 0 30px;
                overflow:hidden;
                .figure-wrap{
                    float:left;
                    width:47%;
                }
                .features-wrap{
                    float:right;
                    width:50%;
                }
            }
            .detail{
                .desc-wrap{
                    td{display:table-cell;}
                }
            }
        }
    }
}
@media screen and (min-width: 1440px) {
    #products{
        .intro-wrap{padding:0;}
        .side-nav{width:310px;}
        .products-cate{
            padding:0;
            .item-group{
                a{padding:0;}
            }
        }
        .products-list,.products-detail{
            width:1090px;
            padding:0 0 0 35px;
        }
        .products-detail{
            header{width:560px;}
            .sample-wrap{
                .figure-wrap{width:460px;}
                .features-wrap{width:560px;}
            }
            .detail{
                .desc-wrap{
                    td{display:table-cell;}
                    .figure{
                        white-space:nowrap;
                        img{width:49%; min-width:235px;}
                    }
                }
            }
        }
    }
}