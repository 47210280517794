#footer {
    background:#052038;
    padding:40px 0;
    color:#fff;
    text-align:center;
    overflow:hidden;
    .editor{
        address{font-style:normal;}
        dl{
            display:inline-block;
            overflow:hidden;
            margin:0;
        }
        dt,dd{
            float:left;
            margin:0;
        }
        dt{clear:left;}
        dd{clear:right;}
    }
}
.footer-nav {
    font-size:0;
    overflow:hidden;
    a{
        display:inline-block;
        &:hover{color:$color1 !important;}
    }
    >ul>li{
        margin:0 0 30px;
        >a{
            @include font-size(13);
        }
    }
    .sub-nav{
        padding:10px 0 0;
        li{
            a{
                @include font-size(12);
                color:#999;
            }
        }
    }
}
.footer-info{
    font-family:Arial, $font-base;
    @include font-size(12);
    padding:20px 0 0;
    .design{padding:20px 0 0;}
    .social-link{
        padding:30px 0;
        a{
            display:inline-block;
            vertical-align:middle;
            height:24px;
            font-size:0;
            text-indent:-9999px;
            background-size:contain !important;
            &+a{margin:0 0 0 30px;}
            &:hover{
                opacity:0.5;
            }
        }
        .icon-facebook{
            width:23px;
            background:url(../images/icon-fb-w.png) center no-repeat;
        }
        .icon-instagram{
            width:25px;
            background:url(../images/icon-ig-w.png) center no-repeat;
        }
        .icon-mail{
            width:25px;
            background:url(../images/icon-mail-w.png) center no-repeat;
        }
        .icon-twitter{
            width:24px;
            background:url(../images/icon-twitter-w.png) center no-repeat;
        }
        .icon-youtube{
            width:53px;
            background:url(../images/icon-youtube-w.png) center no-repeat;
        }
        .icon-skype{
            width:23px;
            background:url(../images/icon-skype-w.png) center no-repeat;
        }
    }
}
#mask-bg {
    @include opacity(0);
    position:fixed;
    left:0; top:0;
    width:100%; height:0;
    background:#000;
    overflow:hidden;
    z-index:998;
    -moz-transition: opacity .3s;
    -o-transition: opacity .3s;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    &.on {
        @include opacity(.8);
        height:100%;
    }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    #footer {
        text-align:left;
        padding:40px 0 80px;
        .w-wrap{padding:0 4%;}
        .editor dl{display:block;}
    }
    .footer-nav {
        float:right;
        >ul>li{
            display:inline-block;
            vertical-align:top;
            margin:0 0 0 30px;
            min-width:80px;
        }
        .sub-nav{max-width:140px;}
    }
    .footer-info{
        float:left;
        padding:0;
    }
}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1280px) {}
@media screen and (min-width: 1440px) {
    #footer {
        .w-wrap{padding:0;}
    }
    .footer-nav{
        >ul>li{
            margin:0 0 0 45px;
        }
        .sub-nav{max-width:250px;}
    }
}