/** download */
#download{
    .download{padding:0 2%;}
    header{text-align:center;}
    .info-title{display:none;}
    .tb-wrap{
        max-width:1190px;
        margin:0 auto 25px;
        text-align:center;
        border-top:5px solid $color2;
        border-bottom:1px solid #999;
        table{
            border-collapse:collapse;
            width:100%;
            font-family:$font-ms;
            th{display:none;}
            td{
                display:block;
                @include font-size(12);
                color:#666;
            }
            .name{
                @include font-size(15);
                font-weight:900;
                color:$color2;
                margin:15px 0 10px;
                border-top:1px solid #999;
            }
            .desc{}
            .size, .btn-wrap{
                display:inline-block;
                vertical-align:middle;
                margin:10px 10px 15px;
            }
            .btn-wrap{
                .btn{
                    background:#666;
                    @include font-size(13);
                    color:#fff;
                    line-height:34px;
                    &:before{
                        @extend %btn-icon;
                        width:20px; height:20px;
                        background:url(../images/icon-download.png) center no-repeat;
                        background-size:contain;
                        margin:0 10px 3px 0;
                    }
                    &:hover{background:$color1;}
                }
            }
        }
    }
}
@media screen and (min-width: 480px) {}
@media screen and (min-width: 640px) {}
@media screen and (min-width: 768px) {}
@media screen and (min-width: 769px) {
    #download{
        .download{padding:0 0 50px;}
        header{padding:0;}
        .info-title{
            display:block;
            margin:0 0 30px;
        }
        .tb-wrap{
            border:none;
            table{
                th,td{
                    display:table-cell;
                    vertical-align:middle;
                    @include font-size(14);
                    padding:12px 0;
                }
                th{
                    background:$color2;
                    color:#fff;
                    white-space:nowrap;
                }
                td{
                    border-bottom:1px solid #999;
                }
                .name{
                    @include font-size(15);
                    border-top:none;
                }
                .desc{}
                .size, .btn-wrap{display:table-cell;}
                .btn-wrap{
                    padding:12px 50px;
                    .btn{
                        font-size:0;
                        text-indent:-9999px;
                        width:36px; height:36px;
                        border-radius:50%;
                        position:relative;
                        &:before{
                            margin:0;
                            position:absolute;
                            top:7px; left:8px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1024px) {}
@media screen and (min-width: 1280px) {}
@media screen and (min-width: 1500px) {}